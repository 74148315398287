import { useCallback, useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";

type Poi = { lat: number; lng: number };

export const Marker = ({
  name,
  position,
  contents,
  filename,
  link,
}: {
  name: string;
  position: Poi;
  contents?: string;
  filename?: string;
  link?: string;
}) => {
  // `markerRef` and `marker` are needed to establish the connection between
  // the marker and infowindow
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  // const handleDownload = () => {
  //   if (!filename || !contents) {
  //     return;
  //   }
  //   // Create a blob with the content and specify the MIME type
  //   const blob = new Blob([contents], { type: "text/plain" });

  //   // Create a link element and trigger the download
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = filename;
  //   link.click();

  //   // Clean up the URL object
  //   URL.revokeObjectURL(link.href);
  // };

  return (
    <>
      <AdvancedMarker
        key={name}
        ref={markerRef}
        position={position}
        onClick={handleMarkerClick}
      />

      {infoWindowShown && (
        <InfoWindow anchor={marker} headerDisabled>
          <div>{name}</div>
          {link && <div>Detail is <a href={link}>Google Doc</a></div>}
          {/* {filename && contents && (
            <button onClick={handleDownload}>Download txt</button>
          )} */}
        </InfoWindow>
      )}
    </>
  );
};
