import {
  APIProvider,
  ColorScheme,
  Map as GoogleMap,
} from "@vis.gl/react-google-maps";
import { VANCOUVER_LOCATION, VISITED_PLACES } from "./constants";
import { Marker } from "./marker";

export const Map = () => {
  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
      onLoad={() => console.log("Maps API has loaded.")}
    >
      <GoogleMap
        style={{ height: "600px" }}
        defaultCenter={VANCOUVER_LOCATION}
        defaultZoom={3}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        mapId={"journey-map"}
        colorScheme={ColorScheme.FOLLOW_SYSTEM}
      >
        {VISITED_PLACES.map((location) => (
          <Marker {...location} />
        ))}
      </GoogleMap>
    </APIProvider>
  );
};
