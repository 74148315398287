import { AppContext } from "AppContext";
import { useContext } from "react";

import { Container } from "./styled";
// import { ContactSession } from '../ContactSession/ContactSession';
// import { ProjectSession } from "ProjectSession/ProjectSession";
import { HeaderComponent } from "./HeaderComponent";
import { ProfileSession } from "ProfileSession/ProfileSession";
import { FooterComponent } from "./Footer";
import { JourneySession } from "JourneySession/JourneySession";
import { FunThingsSession } from "FunThingsSession/FunThingsSession";

export const Home = () => {
  const { theme } = useContext(AppContext);
  return (
    <Container theme={theme}>
      <HeaderComponent />
      <ProfileSession />
      <FunThingsSession />
      <JourneySession />
      {/* <ProjectSession /> */}
      {/* <ContactSession /> */}
      <FooterComponent />
    </Container>
  );
};
